@import 'src/styles/variables/variables';

.Navigator {
  align-items: center;
  display: flex;
  margin-top: 46px;
  padding: 0 12px;

  .Next,.Prev {
    min-width: auto;
    padding: 0;
    color: $core1;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    outline: none;
    border: none;
    background-color: transparent;
  }

  hr {
    flex: 1;
    border: 1px solid $core1;
    background: $core1;
    margin: 0 10px;
  }
}

